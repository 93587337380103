import React, { useState, useRef } from "react";
import "./Accordian.css";

const Accordion = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const contentRefs = useRef([]);

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const items = [
    {
      title: "Accordion Item 1",
      content: "This is the content for the first accordion item.",
    },
    {
      title: "Accordion Item 2",
      content:
        "This is the content for the second accordion item. This is the content for the second accordion item This is the content for the second accordion item.  This is the content for the second accordion item This is the content for the second accordion item.  This is the content for the second accordion item This is the content for the second accordion item. ",
    },
    {
      title: "Accordion Item 3",
      content: "This is the content for the third accordion item.",
    },
  ];

  return (
    <section className="faq_section">
      <div className="container container_big">
        <div className="faq_section_inner">
          <div className="left_side_content">
            <h3 className="heading">Frequently Asked Questions</h3>
            <p>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit.
              Corrupti, et. Commodi veniam rerum ipsa sit facilis doloremque
              aliquid mollitia harum numquam ipsam nostrum voluptatibus
              veritatis minima minus, eveniet ab quia assumenda debitis. Optio,
              quibusdam labore. Soluta culpa ipsa alias, distinctio modi eos
              quae laboriosam suscipit placeat sunt perferendis dolorum
              consectetur!
            </p>
          </div>
          <div className="accordion_section right_side_content">
            {items.map((item, index) => (
              <div
                key={index}
                className={`accordion_item ${
                  activeIndex === index ? "open" : ""
                }`}
              >
                <div
                  className="accordion_button"
                  onClick={() => handleToggle(index)}
                >
                  <div className="accordian_title">{item.title}</div>
                  <div className="arrow_acordian">
                    <svg
                      className="plus_image"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 128 128"
                    >
                      <path
                        style={{ fill: "#303030" }}
                        d="M128 63.954c0 2.006-.797 3.821-2.136 5.127-1.308 1.337-3.125 2.133-5.166 2.133H71.302v49.356c0 4.012-3.284 7.292-7.302 7.292-2.009 0-3.827-.828-5.166-2.134-1.308-1.337-2.136-3.152-2.136-5.159V71.214H7.302c-4.05 0-7.302-3.248-7.302-7.26 0-2.006.797-3.853 2.136-5.159a7.279 7.279 0 0 1 5.166-2.134h49.395V7.306c0-4.012 3.284-7.26 7.302-7.26 2.009 0 3.827.828 5.166 2.133a7.238 7.238 0 0 1 2.136 5.127v49.356h49.395A7.276 7.276 0 0 1 128 63.954z"
                      />
                    </svg>
                    <svg
                      className="minus_image"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 128 128"
                    >
                      <path
                        style={{ fill: "#303030" }}
                        d="M125.61 71.238H2.39A2.39 2.39 0 0 1 0 68.848v-9.787a2.39 2.39 0 0 1 2.39-2.39h123.22a2.39 2.39 0 0 1 2.39 2.39v9.787a2.39 2.39 0 0 1-2.39 2.39z"
                      />
                    </svg>
                  </div>
                </div>
                <div
                  className="accordion-content"
                  ref={(el) => (contentRefs.current[index] = el)}
                  style={{
                    maxHeight:
                      activeIndex === index
                        ? `${contentRefs.current[index]?.scrollHeight}px`
                        : "0px",
                  }}
                >
                  <div className="accordian_content_inner">
                    <p>{item.content}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Accordion;
