import React from "react";
import "./LogoGrid.css";

const LogoGrid = () => {
  const logos = [
    {
      icon: require("../assets/img_react_js_icon.svg"),
      alt: "React js",
    },

    {
      icon: require("../assets/img_vuejs.svg"),
      alt: "vuejs",
    },
    {
      icon: require("../assets/img_javascript-_icon.svg"),
      alt: "javascript",
    },
    {
      icon: require("../assets/img_salesforc_icon.svg"),
      alt: "salesforce",
    },
    {
      icon: require("../assets/img_aws_icon.svg"),
      alt: "aws",
    },
    {
      icon: require("../assets/img_angular_icon.svg"),
      alt: "angular",
    },
    {
      icon: require("../assets/img_python_rogramming_language_icon.svg"),
      alt: "python",
    },
    {
      icon: require("../assets/img_drupal.svg"),
      alt: "php",
    },
    {
      icon: require("../assets/img_ruby_on_rails.svg"),
      alt: "ruby on rails",
    },
    {
      icon: require("../assets/img_nodejs_icon.svg"),
      alt: "Node js",
    },
    {
      icon: require("../assets/img_fluter.svg"),
      alt: "fluter",
    },
  
    {
      icon: require("../assets/img_svelte_icon.svg"),
      alt: "svelte",
    },
    {
      icon: require("../assets/img_nextjs__icon.svg"),
      alt: "nextjs",
    },
    {
      icon: require("../assets/img_artificial-intelligence_ai_icon.svg"),
      alt: "ai",
    },
    {
      icon: require("../assets/img_zapier_icon.svg"),
      alt: "zapier",
    },
    {
      icon: require("../assets/img_hubspot_icon.svg"),
      alt: "hubspot",
    },
    {
      icon: require("../assets/img_mailchimplogo.svg"),
      alt: "mailchimp",
    },
    {
      icon: require("../assets/img_google_analytics_icon.svg"),
      alt: "google_analytics",
    },
    {
      icon: require("../assets/img_wordpress.svg"),
      alt: "wordpress",
    },
    {
      icon: require("../assets/img_magento.svg"),
      alt: "magento",
    },
    {
      icon: require("../assets/img_shopify.svg"),
      alt: "shopify",
    },
    {
      icon: require("../assets/img_laravel.svg"),
      alt: "laravel",
    },
  ];

  return (
    <section className="heading_with_logo_items">
      <div className="container">
        <div className="heading_with_logo_items_inner">
          <div className="heading_wrapper">
            {/* heading class uses for css of any heading tags */}

            {/* <h2 className="heading">different logos of languages</h2> */}
            

            {/* p tag use for paragraph styling */}


            {/* <p>
              Each of our design and coding experts on board have gone through a
              testing process where only ~2% succeed.Each of our design and
              coding experts on board have gone through a testing process where
              only ~2% succeed.
            </p> */}
          </div>
          <div className="logo_grid">
            {logos.map((logo, index) => (
              <div key={index} className="logo_grid_item">
                <div className="img_wrapper">
                  <img
                    src={logo.icon.default}
                    alt={logo.alt}
                    className="logo_img"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default LogoGrid;
