import React from "react";
import "./LeftrightimageandContent.css";
import { Link } from "react-router-dom";
import videoSrc from "./Words.mp4";
// import icon from ''

const LeftrightimageandContent = () => {
  return (
    <section className="Left_right_image_and_content">
      <div className="Left_right_image_and_content_inner">
        <div className="container container_big">
          <div className="Left_right_image_and_content_wrapper left">
            <div className="images">
                <img src={require("../assets/Frame81.jpg")} alt="image" className="img" />
                <div className="logoimage">
                  <img src={require("../assets/google.jpg")} alt="image" className="logoimg"/>
                </div>
                <div className="logoimagehover">
                  <img src={require("../assets/google.jpg")} alt="image" className="logoimghover"/>
                </div>
            </div>
            <div className="content">
              <h3 className="heading">
                The Medicare Payment Advisory Commission (MedPAC)
              </h3>
              <div className="description">
                Washington, District of Columbia
              </div>
              <div className="btn">
                <Link to="/contact" className="cash_study">
                  Read Cash Study
                </Link>
              </div>
            </div>
          </div>
          <div className="Left_right_image_and_content_wrapper right">
            <div className="images">
              <img
                src={require("../assets/Frame82.jpg")}
                alt="image"
                className="img"
              />
              <img
                src={require("../assets/google.jpg")}
                alt="image"
                className="logoimg"
              />
              <img
                src={require("../assets/google.jpg")}
                alt="image"
                className="logoimghover"
              />
            </div>
            <div className="content">
              <h3 className="heading">
                The Medicare Payment Advisory Commission (MedPAC)
              </h3>
              <div className="descriptio">Washington, District of Columbia</div>
              <div className="btn">
                <Link to="/contact" className="cash_study">
                  Read Cash Study
                </Link>
              </div>
            </div>
          </div>
          <div className="Left_right_image_and_content_wrapper left">
            <div className="video_container">
                <video className="video_content" autoPlay muted loop>
                    <source src={videoSrc} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <div className="logoimage">
                  <img src={require("../assets/google.jpg")} alt="image" className="logoimg"/>
                </div>
                <div className="logoimagehover">
                  <img src={require("../assets/google.jpg")} alt="image" className="logoimghover"/>
                </div>
            </div>
            <div className="content">
              <h3 className="heading">
                The Medicare Payment Advisory Commission (MedPAC)
              </h3>
              <div className="descriptio">Washington, District of Columbia</div>
              <div className="btn">
                <Link to="/contact" className="cash_study">
                  Read Cash Study
                </Link>
              </div>
            </div>
          </div>
          <div className="Left_right_image_and_content_wrapper right">
            <div className="video_container">
                <video className="video_content" autoPlay muted loop>
                    <source src={videoSrc} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <div className="logoimage">
                  <img src={require("../assets/google.jpg")} alt="image" className="logoimg"/>
                </div>
                <div className="logoimagehover">
                  <img src={require("../assets/google.jpg")} alt="image" className="logoimghover"/>
                </div>
            </div>
            <div className="content">
              <h3 className="heading">
                The Medicare Payment Advisory Commission (MedPAC)
              </h3>
              <div className="descriptio">Washington, District of Columbia</div>
              <div className="btn">
                <Link to="/contact" className="cash_study">
                  Read Cash Study
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default LeftrightimageandContent;
