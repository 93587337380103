import React, { useEffect } from "react";
import "./About.css"; // CSS file for styling

const About = () => {
    useEffect(() => {
        // Function to animate number counting
        const animateCount = (element, targetValue, format) => {
            let startValue = 0;
            const increment = targetValue / 100; // Adjust the increment for smooth counting
            const interval = setInterval(() => {
                startValue += increment;
                if (startValue >= targetValue) {
                    clearInterval(interval);
                    if (format === 'percent') {
                        element.innerText = targetValue + "%"; // Percentage format
                    } else if (format === 'currency') {
                        element.innerText = "$" + targetValue.toLocaleString(); // Currency format
                    } else {
                        element.innerText = targetValue + "+"; // Number format
                    }
                } else {
                    if (format === 'percent') {
                        element.innerText = Math.floor(startValue) + "%"; // Percentage format
                    } else if (format === 'currency') {
                        element.innerText = "$" + Math.floor(startValue).toLocaleString(); // Currency format
                    } else {
                        element.innerText = Math.floor(startValue) + "+"; // Number format
                    }
                }
            }, 50);
        };

        // IntersectionObserver to trigger the counting animation
        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    // Ensure we are observing the correct section
                    const statsElements = entry.target.querySelectorAll(".stat h3");
                    statsElements.forEach((stat) => {
                        const targetValue = parseInt(stat.dataset.target);
                        const format = stat.dataset.format; // Get the format type
                        animateCount(stat, targetValue, format); // Trigger count animation
                    });
                    entry.target.classList.add("visible"); // Optional: Add class for fade-in effect
                    observer.unobserve(entry.target); // Stop observing after animation starts
                }
            });
        }, { threshold: 0.5 }); // Trigger animation when 50% of section is visible

        const statsSection = document.querySelector(".statistics");
        observer.observe(statsSection); // Start observing the stats section

        return () => {
            observer.disconnect(); // Cleanup on component unmount
        };
    }, []);

    return (
        <section className="about-us-section">
            <div className="container container_big">
                {/* Statistics */}
                <div className="statistics">
                    <div className="stat">
                        <h3 data-target="30" data-format="currency">0</h3>
                        <p>Hourly flat rate (no hidden fees)</p>
                    </div>
                    <div className="stat">
                        <h3 data-target="1400" data-format="number">0</h3>
                        <p>Web development projects delivered</p>
                    </div>
                    <div className="stat">
                        <h3 data-target="90" data-format="percent">0</h3>
                        <p>Of our clients return with more work</p>
                    </div>
                </div>

                {/* Description */}
                <div className="company-description">
                    <p>Founded by Iko Kostadinov in 2009, Pixel2Coding has been growing over the years, becoming a reliable and trustworthy remote partner to digital agencies, startups, eCommerce, and Fortune 500 businesses.</p>
                    <p>What makes us different from our competitors is that we use our Technical Project Managers as the bridge between clients and designers/developers, making the full process hassle-free for you. We follow strict internal rules in terms of working ethics, quality, and dedication.</p>
                    <p>Our offices are located in Macedonia, Europe, and all of our team members are true experts in their fields, experienced in the latest modern technologies.</p>
                    <p>If you have any questions, feel free to contact us.</p>
                </div>
            </div>
        </section>
    );
};

export default About;
