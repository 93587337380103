import React from "react";
import "./Workflow.css"; // Create this CSS file for custom styling

const Workflow = () => {
  const steps = [
    "Detailed review of the brief / specifics for the work request by the PM / Team.",
    "Estimation to deliver the work request sent immediately to the client.",
    "Start working on the work request.",
    "Communication between PM and Client through internal channel for constant update and progress.",
    "Internal QA after the work request is completed. Including functionality / front-end / checklist…",
    "Feedback communication between PM and Client.",
    "Completion of the work request and sign off."
  ];

  return (
    <section className="workflow-section">
      <div className="container">
        <h2>Workflow Process</h2>
        <ol className="workflow-steps">
          {steps.map((step, index) => (
            <li key={index} className="workflow-step">
              {step}
            </li>
          ))}
        </ol>
        <p className="workflow-description">
          We put great attention to our communication and internal workflow system in place that produces error-free results and on-time deliveries.
        </p>
      </div>
    </section>
  );
};

export default Workflow;
