import React from "react";
import Header from "../sections/Header";
import Footer from "../sections/Footer";
import Innerpagebanner from "../sections/Banner_inner_page";
import About from "../sections/About";
import LogoGrid from "../sections/LogoGrid";
const AboutPage = () => {
  return (
    <>
      <Header />
      <Innerpagebanner
        heading="About Us"
        bgimage={require("../assets/image.png")}
      />
      <About />
      <LogoGrid />
      <Footer />
    </>
  );
};

export default AboutPage;
