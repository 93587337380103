import React from "react";
import "./Suitable_for_sec.css";
const Services = [
  {
    icon: require("../assets/ad.jpg"),
    alt: "Web / Ad / Marketing Agencies",
    title: "Web / Ad / Marketing Agencies",
  },
  {
    icon: require("../assets/web.jpg"),
    alt: "Web / Design Studios",
    title: "Web / Design Studios",
  },
  {
    icon: require("../assets/freelancer.jpg"),
    alt: "Freelancers",
    title: "Freelancers",
  },
  {
    icon: require("../assets/startups.jpg"),
    alt: "Startups",
    title: "Startups",
  },
  {
    icon: require("../assets/business.jpg"),
    alt: "Businesses",
    title: "Businesses",
  },
  {
    icon: require("../assets/saas.jpg"),
    alt: "SaaS Companies",
    title: "SaaS Companies",
  },
];
const Suitableforsection = () => {
  return (
    <>
      <section className="suitable_servieces_wrapper">
        <div className="container container_big">
          <div className="suitable_servieces_wrapper_inner">
            <h2 className="heading">Suitable For</h2>
            <div className="suitable_servieces__grid">
              {Services.map((service, index) => (
                <div
                  key={index}
                  className="suitable_servieces__grid_item"
                >
                  <img src={service.icon} alt={service.alt} />
                  <h6 className="title">
                    {service.title}
                  </h6>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Suitableforsection;
