import React from "react";
import Header from "../sections/Header";
import Footer from "../sections/Footer";
import Innerpagebanner from "../sections/Banner_inner_page";
import Clients from "../sections/Clients";
const ServicesPage = () => {
  return (
    <>
      <Header />
      <Innerpagebanner
        heading="our Service"
        bgimage={require("../assets/image.png")}
      />
      <Clients />
      <Footer />
    </>
  );
};

export default ServicesPage;
