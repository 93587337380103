import React from "react";
import "./Banner_inner_page.css";
const Innerpagebanner = ({heading, content , bgimage}) => {
  return (
    <div className="inner_page_banner" style={{ backgroundImage: `url(${bgimage})`}}>
      <div className="container container_small">
        <div className="inner_page_banner_inner">
          <h2>{heading}</h2>
          <p>{content}</p>
        </div>
      </div>
    </div>
  );
};

export default Innerpagebanner;
