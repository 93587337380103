import React from "react";
import Footer from "../sections/Footer";
import Header from "../sections/Header";
import Innerpagebanner from "../sections/Banner_inner_page";
import Accordion from "../sections/Accordian";
const FaqPage = () => {
  return (
    <>
      <Header />
      <Innerpagebanner
        heading="Faq"
        bgimage={require("../assets/image.png")}
      />
      <Accordion />
      <Footer />
    </>
  );
};

export default FaqPage;
