import React from "react";
import "./LandingPage.css";
import Hero from "../sections/Hero";
import Footer from "../sections/Footer";
import Header from "../sections/Header";
import Clients from "../sections/Clients";
import WhatWeAreDoing from "../sections/WhatWeAreDoing";
import AdvantagesSection from "../sections/Advantages";
import About from "../sections/About";
import Workflow from "../sections/Workflow";
import Suitableforsec from "../sections/Suitable_for_sec";
const LandingPage = () => {
  return (
    <>
      <Header />

      {/* Hero Section */}
      <Hero />
      <Clients />
      <WhatWeAreDoing />
      <AdvantagesSection />
      <Workflow />
      <Suitableforsec />
      <About />
      <Footer />
    </>
  );
};

export default LandingPage;
