import React, { useState } from "react";
import "./ContactUs.css";
import Footer from "../sections/Footer";
import Header from "../sections/Header";
import Innerpagebanner from "../sections/Banner_inner_page";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    surename: "",
    email: "",
    phone: "",
    message: "",
  });

  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.firstName.trim())
      newErrors.firstName = "First name is required";
    if (!formData.surename.trim()) newErrors.surename = "Surname is required";
    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
    } else if (!validateEmail(formData.email)) {
      newErrors.email = "Invalid email format";
    }
    if (!formData.phone.trim()) newErrors.phone = "Phone number is required";
    if (!formData.message.trim()) newErrors.message = "Message is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    // Submit form data to PHP
    try {
      const response = await fetch("contact-post.php", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setSuccessMessage("Your message has been sent successfully!");
        setFormData({
          firstName: "",
          surename: "",
          email: "",
          phone: "",
          message: "",
        });
        setErrors({});
      } else {
        setSuccessMessage("Failed to send your message. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setSuccessMessage("Something went wrong. Please try again later.");
    }
  };

  return (
    <>
      <Header />
      <Innerpagebanner
        heading="Contact us"
        content="Scale-up your team with our on-demand design and development
            services. World-class coding experts are available to start
            immediately after approval."
        bgimage={require("../assets/image.png")}
      />
      <div className="contact-us">
        <section className="contact-form-section">
          <div className="container container_small">
            <div className="contact-form-section_wrapper">
              <div className="heading_section_wrapper">
                <div className="content"></div>
              </div>
              <form className="contact-form" onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="firstname">First name</label>
                  <input
                    type="text"
                    name="firstName"
                    placeholder="First Name"
                    value={formData.firstName}
                    onChange={handleChange}
                  />
                  {errors.firstName && (
                    <span className="error">{errors.firstName}</span>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="surename">Surname</label>
                  <input
                    type="text"
                    name="surename"
                    placeholder="Your Surname"
                    value={formData.surename}
                    onChange={handleChange}
                  />
                  {errors.surename && (
                    <span className="error">{errors.surename}</span>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    name="email"
                    placeholder="Your Email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                  {errors.email && (
                    <span className="error">{errors.email}</span>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="phone">Phone number</label>
                  <input
                    type="tel"
                    name="phone"
                    placeholder="+91 0223223232"
                    value={formData.phone}
                    onChange={handleChange}
                  />
                  {errors.phone && (
                    <span className="error">{errors.phone}</span>
                  )}
                </div>
                <div className="form-group input_textarea">
                  <label htmlFor="message">Enter Message</label>
                  <textarea
                    name="message"
                    placeholder="Type Something Here"
                    rows="5"
                    value={formData.message}
                    onChange={handleChange}
                  ></textarea>
                  {errors.message && (
                    <span className="error">{errors.message}</span>
                  )}
                </div>
                <div className="form-group submit-wrapper">
                  <button type="submit" className="submit-button">
                    Submit
                  </button>
                </div>
              </form>
              {successMessage && (
                <div className="success-message">{successMessage}</div>
              )}
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};

export default ContactUs;
