import React from "react";
import Header from "../sections/Header";
import Footer from "../sections/Footer";
import LeftrightimageandContent from "../sections/LeftrightimageandContent";

const Portfolio = () => {
  return (
    <>
      <Header />
      <LeftrightimageandContent />
      <Footer />
    </>
  );
};

export default Portfolio;
