import React from "react";
import "./Advantages.css"; // CSS file for styling

const Advantages = [
  {
    icon: require('../assets/digital.jpg'),
    alt: "On-Demand Services",
    title: "On-Demand Services",
    description: "You can scale-up your team as we offer a wide range of design and development services available immediately."
  },
  {
    icon: require('../assets/new-age.jpg'),
    alt: "Communication Is Key",
    title: "Communication Is Key",
    description: "We provide flawless communication through our channels, keeping you in the loop at any given time."
  },
  {
    icon: require('../assets/power.jpg'),
    alt: "Technical Project Managers",
    title: "Technical Project Managers",
    description: "You will deal directly with only one Technical Project Manager, making the full process hassle-free for you."
  },
  {
    icon: require('../assets/result.jpg'),
    alt: "Immediate Start",
    title: "Immediate Start",
    description: "We work fast and efficiently, starting projects/tasks immediately after approval. We’re here 24/7."
  },
  {
    icon: require('../assets/customize.jpg'),
    alt: "Competitive Pricing",
    title: "Competitive Pricing",
    description: "We typically work on a project/task basis, taking into consideration a flat rate of $30/hour."
  },
  {
    icon: require('../assets/ent.jpg'),
    alt: "Handpicked Team",
    title: "Handpicked Team",
    description: "Each of our design and coding experts on board have gone through a testing process where only ~2% succeed."
  },
];

const AdvantagesSection = () => {
  return (
    <section className="advantages-section">
      <div className="container container_big">
        <h2>Our Advantages</h2>
        <div className="advantages-grid">
          {Advantages.map((advantage, index) => (
            <div className="advantage-box" key={index}>
              <div className="icon">
                <img src={advantage.icon} alt={advantage.alt} />
              </div>
              <h3>{advantage.title}</h3>
              <p>{advantage.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default AdvantagesSection;
