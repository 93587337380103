import React from "react";
import "./WhatWeAreDoing.css"; // You can create a separate CSS file for this section

const WhatWeAreDoing = () => {
  return (
    <section className="what-we-are-doing-section">
      <div className="container container_big">
        {/* Section Title */}
        <h2>What Are We Doing?</h2>

        {/* Strategy Description */}
        <p className="important_text">
          Our strategy is based on delivering world-class results at competitive
          pricing. Each project includes QA testing and a dedicated project
          manager.
        </p>

        {/* Services List */}
        <h3>Services We Offer:</h3>
        <ul>
          <li>Front-End Development / Engineering</li>
          <li>Web Design, UI / UX</li>
          <li>CMS Coding / Customization</li>
          <li>Backend Software Development</li>
          <li>Full Stack Developers</li>
        </ul>

        <p className="important_text">
          From simple web solutions to complex platforms, we have been providing
          equally great service to clients of all sizes. Our fee structure
          offers the ability to work on a project/task basis (taking into
          consideration an hourly rate) or using an hourly rate for
          part-time/full-time hiring.
        </p>
      </div>
    </section>
  );
};

export default WhatWeAreDoing;
