import React from "react";
import "./Footer.css";
import logo from "./logo.png";
import { Link, useLocation } from "react-router-dom";
const Footer = () => {
  const location = useLocation();
  const menuitem = [
    { name: "Home", to: "/" },
    { name: "service", to: "/service" },
    { name: "About", to: "/about" },
    { name: "Contact", to: "/contact" },
  ];
  return (
    <footer>
      <div className="container container_big">
        <div className="footer_inner">
          <div className="footer_wrapper">
            <div className="footer_logo">
              <Link to="/">
                <img src={logo} alt="" />
              </Link>
            </div>
            <div className="footer_item_list">
              <ul className="footer_item_list_wrapper">
                {menuitem.map((item, index) => (
                  <li
                    key={index}
                    className={`footer_menu_item ${
                      location.pathname === item.to ? "active" : ""
                    }`}
                  >
                    <Link to={item.to}>{item.name}</Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="footer_content">
            <p>&copy; 2024 Pixel2Coding. All Rights Reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
