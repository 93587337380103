import React from "react";
import "./Clients.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";  // Correct way to import Swiper styles

// Dynamically import the images using require to ensure Webpack handles them
const ClientLogos = [
    require("../assets/google.jpg"),
    require("../assets/vm.jpg"),
    require("../assets/cisco.jpg"),
    require("../assets/dell.jpg"),
    require("../assets/ps.jpg")
];

const Clients = () => {
    return (
        <section className="clients-section">
            <div className="container container_big">
                {/* Section Title */}
                <h2>We Offer Solutions to Your Problems</h2>

                {/* Content */}
                <div className="content">
                    <p className=" mb-big" >
                        In reality, the majority of the companies face similar issues as shown below:
                    </p>
                    <ul>
                        <li>There is an urgent project to be done yesterday.</li>
                        <li>You don’t have the required expertise.</li>
                        <li>Your team is fully booked and you need external help ASAP.</li>
                        <li>There is an open position for full or part-time.</li>
                        <li>You need a highly skilled developer for more complex tasks.</li>
                    </ul>

                    <h3>SOLUTION: Partner with Pixel2Coding and have your problems solved immediately.</h3>
                    <p className="">
                        Start the onboarding process (Just a few steps to get to know each other) by contacting us
                        through email or form. We are problem solvers.
                    </p>
                </div>

                {/* Logo Slider */}
                <div className="logo-slider">
                    <Swiper
                        spaceBetween={20}
                        slidesPerView={4}
                        loop={true}
                        breakpoints={{
                            1024: {
                                slidesPerView: 4,
                            },
                            768: {
                                slidesPerView: 3,
                            },
                            480: {
                                slidesPerView: 2,
                            },
                        }}
                    >
                        {ClientLogos.map((logo, index) => (
                            <SwiperSlide key={index}>
                                <img src={logo} alt={`Client logo ${index + 1}`} className="client-logo" />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
        </section>
    );
};

export default Clients;
