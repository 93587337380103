import React from "react";
import "./Hero.css";
import { Link } from "react-router-dom";
import videoSrc from "./Words.mp4"; // Correctly import video

const Hero = () => {
  return (
    <section className="hero">
      <div className="video-container">
        {/* Background video */}
        <video className="hero-video" autoPlay muted loop>
          <source src={videoSrc} type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        {/* Transparent Overlay */}
        <div className="overlay"></div>

        {/* Content over the video */}
        <div className="hero-content">
          <h1>On-Demand Remote Coding Experts For Your Business</h1>
          <p>
            Scale-up your team with our on-demand design and development
            services. World-class coding experts are available to start
            immediately after approval.
          </p>
          <div className="btn">
            <Link to="/contact" className="contact">
              Get In Touch
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
