import React, { useState } from "react";
// import { Link as ScrollLink } from "react-scroll"; // Renamed Link to ScrollLink for clarity
import { Link, useLocation } from "react-router-dom"; // Import for homepage navigation
import "./Header.css";
import logo from "./logo.png";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const location = useLocation();
  const menuitem = [
    { name: "Home", to: "/" },
    { name: "Service", to: "/service" },
    { name: "About", to: "/about" },
    { name: "Contact", to: "/contact" },
  ];
  return (
    <>
      <header className={`header  ${isMenuOpen ? "open" : ""}`} id="header">
        <div className="container container_big">
          <div className="header_inner">
            {/* Logo linking to the homepage */}
            <div className="logo">
              <Link to="/">
                <img src={logo} alt="Pixel2coding" />
              </Link>
            </div>
            <button className="hamburger" onClick={toggleMenu}>
              <span className="hamburger-line hamburger-line_one"></span>
              <span className="hamburger-line hamburger-line_two"></span>
              <span className="hamburger-line hamburger-line_three"></span>
            </button>
            <nav className="nav">
              <ul className="menu_wrapper">
                {menuitem.map((item, index) => (
                  <li
                    key={index}
                    className={`menu_item ${
                      location.pathname === item.to ? "active" : ""
                    }`}
                  >
                    <Link to={item.to}>{item.name}</Link>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
